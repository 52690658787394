import React, { useState } from "react";
import { useDispatch } from "react-redux";

//redux actions
import { getOTP, verifyOTP } from "../../../redux/actions/otherActions";

//material ui
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CachedIcon from "@material-ui/icons/Cached";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import OtpInput from "react-otp-input";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#008000",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogRoot: {
      "& .MuiButtonBase-root": {
        outline: "none",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: "green",
      },
      "& .MuiDialog-paperWidthMd": {
        width: 400,
      },
    },
    labelRoot: {
      fontSize: "14px",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
    },
    lable: {
      fontSize: 16,
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: "bold",
      textAlign: "center",
      color: "#383838",
    },
    button: {
      fontSize: 14,
      color: "#fff",
      fontWeight: 500,
      fontFamily: "'Fira Sans', sans-serif",
      textTransform: "none",
    },
    otpinput: {
      width: 40,
      margin: "4px 10px",
      padding: theme.spacing(1),
      border: "1px solid #F1DBA8",
      borderRadius: "4px",
      textAlign: "center",
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "'Fira Sans', sans-serif",
      outline: "none",
      boxShadow: "0px 0px 7px green",
    },
    clearbutton: {
      textTransform: "none",
      fontSize: 14,
      fontFamily: "'Fira Sans', sans-serif",
      color: "#3C99DC",
    },
  })
);

export const VerifyOtp = (props) => {
  const classes = useStyles();
  const { open, handleClose, mobileNumber } = props;
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");

  // const handleChange = (element, index) => {
  //   if (isNaN(element.value)) return false;

  //   setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

  //   //Focus next input
  //   if (element.nextSibling) {
  //     element.nextSibling.focus();
  //   }
  // };

  // clear OTP
  const handleClearOtp = () => {
    setOtp("");
  };

  // verify OTP
  const handleVerifyOtp = (otp) => {
    const otpData = {
      otp: otp,
    };

    const number = mobileNumber;

    // Only dispatch `verifyOTP` if in PROD environment
    if (process.env.REACT_APP_ENV === 'PROD') {
      dispatch(verifyOTP(number, otpData));
    }

    // Clear OTP and close modal
    handleClearOtp();
    handleClose();
  };

  // Resend OTP
  const handleResendOtp = () => {
    const phoneNumber = mobileNumber; // Assuming `mobileNumber` is a valid variable in scope.

    if (process.env.REACT_APP_ENV === 'PROD') {
      dispatch(getOTP(phoneNumber)); // Assuming `getOTP` requires only `phoneNumber`.
    }

  };

  // Counter
  // const [counter, setCounter] = React.useState(59);
  // React.useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={classes.dialogRoot}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Verify OTP
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <InputLabel className={classes.lable}>Mobile Number</InputLabel>
            <TextField
              id="mobileNumber"
              disabled
              name="mobileNumber"
              variant="outlined"
              size="small"
              value={mobileNumber}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.labelRoot,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <div
              className={classes.otp_form}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <InputLabel className={classes.lable}>One Time OTP</InputLabel>

              <OtpInput
                onChange={setOtp}
                value={otp}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  width: 40,
                  height: 40,
                  margin: "4px 10px",
                  padding: "10px",
                  border: "1px solid #F1DBA8",
                  borderRadius: "4px",
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: 600,
                  fontFamily: "'Fira Sans', sans-serif",
                  outline: "none",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
                numInputs={4}
                separator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center", padding: 0 }}>
            <Button
              className={classes.clearbutton}
              onClick={handleClearOtp}
              style={{ outline: "none" }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      {/* <Box mt={3}>
        <Typography fontWeight={500} align="center" color="textSecondary">
          Resend OTP in
          <span style={{ color: "green", fontWeight: "bold" }}>
            00:{counter}
          </span>
        </Typography>
      </Box> */}

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CachedIcon />}
          style={{ backgroundColor: "#008000" }}
          onClick={handleResendOtp}
        >
          Resend OTP
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<DoneAllIcon />}
          style={{ backgroundColor: "#008000" }}
          onClick={(e) => handleVerifyOtp(otp)}
        >
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};
